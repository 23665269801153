import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  PLATFORM_ID,
  //  Renderer2,
} from '@angular/core';

// import { OneSignal } from 'onesignal-ngx';

// import { ScriptService } from 'src/app/services/helpers/script.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'peperosaweb';

  constructor(
//    private oneSignal: OneSignal,
    @Inject(PLATFORM_ID) private platformId: any //    private readonly scriptService: ScriptService,
  ) //    private renderer: Renderer2
  {

  }

  private fontsLoaded = false;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
   //   var cc = localStorage.getItem('google_adsense_settings')
   //   console.log(cc)
     /* this.oneSignal.init({
        appId: "90737fb5-1477-4e97-ae40-b0ddf1457c8f",
      }); */
    }
    if (isPlatformBrowser(this.platformId)) {
      if (!this.fontsLoaded) {
        this.loadGoogleFonts();
      }
    }
  }

  ngAfterViewInit(): void {
  }

  private loadGoogleFonts() {
    const link = document.createElement('link');
    link.href =
      'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@300;400;600&family=Lato:wght@300;400;600&display=swap';
    link.rel = 'stylesheet';

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(link);

    this.fontsLoaded = true;
  }

}
