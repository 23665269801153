import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'
import { HttpClientModule } from '@angular/common/http';
// import { PRECONNECT_CHECK_BLOCKLIST } from '@angular/common';


import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt);


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  exports: [AppComponent],
  providers: [
    provideClientHydration(),
    { provide: LOCALE_ID, useValue: "it-IT" }
   /* {
      provide: PRECONNECT_CHECK_BLOCKLIST,
      useValue: 'https://cdn.pepe-rosa.com',
    },
*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
