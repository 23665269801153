import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

export function htmlFiles(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.endsWith('.html')
    ? {
        consumed: url,
        posParams: {
          id: new UrlSegment(url[0].path, {}),
        },
      }
    : null;
}


const routes: Routes = [
  {
    matcher: htmlFiles,
    loadChildren: () =>
      import('./templates/article/article.module').then((m) => m.ArticleModule),
  },
  {
    path: 'archive',
    loadChildren: () =>
      import('./templates/archive/archive.module').then(
        (m) => m.ArchiveModule
      ),
  },
  {
    path: ':category',
    loadChildren: () =>
      import('./templates/category/category.module').then(
        (m) => m.CategoryModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./templates/homepage/homepage.module').then(
        (m) => m.HomepageModule
      ),
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./templates/uvod/uvod.module').then(
        (m) => m.UvodModule
      ),
  },
 /* {
    path: '',
    loadChildren: () =>
      import('./templates/uvod/uvod.module').then(
        (m) => m.UvodModule
      ),
  },*/
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
